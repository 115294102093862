<template>
  <!-- <client-only>: prevent Mismatching childNodes vs. VNodes -->
  <client-only>
    <component
      :is="type"
      v-bind="$attrs"
      :to="to"
      class="min-w-[100px] px-2.5 py-1.5 border text-center font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 hover:no-underline transition"
      :class="classList"
    >
      <slot />
    </component>
  </client-only>
</template>

<style scoped>
[disabled] {
  @apply bg-opacity-30 text-opacity-50 cursor-not-allowed !important;
}
</style>

<script setup>
import { computed } from "vue";

const colors = {
  primary:
    "border-transparent !text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 hover:!text-gray-200",
  secondary:
    "border-transparent text-primary-700 bg-primary-100 hover:bg-primary-200 focus:ring-primary-500 hover:text-primary-700",
  black:
    "border-transparent text-white bg-gray-900 hover:bg-gray-700 focus:ring-gray-500",
  white:
    "border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-50",
};

const sizes = {
  base: "text-base",
  xs: "text-xs",
  sm: "text-sm",
  xl: "text-xl",
};

const props = defineProps({
  color: {
    required: false,
    type: String,
    default: "primary",
  },
  size: {
    required: false,
    type: String,
    default: "base",
  },
  block: {
    required: false,
    type: Boolean,
    default: false,
  },
  rounded: {
    required: false,
    type: Boolean,
    default: true,
  },
  to: {
    type: String,
    default: null,
  },
});

const type = computed(() => {
  if (props.to) {
    return "nuxt-link";
  } else {
    return "button";
  }
});
const colorsClass = computed(() => {
  return colors[props.color];
});

const sizeClass = computed(() => {
  return sizes[props.size];
});

const roundedClass = computed(() => {
  return props.rounded ? "rounded" : "";
});

const displayClass = computed(() => {
  return props.block ? "block w-full" : "inline-block";
});

const classList = computed(() => {
  const classList = `${displayClass.value} ${colorsClass.value} ${sizeClass.value} ${roundedClass.value}`;
  return classList;
});
</script>
